import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {TranslateService} from '@ngx-translate/core';
import {of, Subject} from 'rxjs';
import {filter, map, switchMap, take, takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';

import {navigation} from 'app/navigation/navigation';
import {locale as navigationEnglish} from 'app/navigation/i18n/en';
import {AuthService} from './kluh-manager/auth.service';
import {isMacOS} from './kluh-manager/helpers/kluh';
import {UserAuthorityDaoService} from './kluh-manager/main/user-authority/user-authority-dao.service';
import {FirebaseUserDetails} from './kluh-manager/models';
import {DesktopServerService} from './kluh-manager/main/desktop-server/desktop-server.service';
import {NavigationEnd, Router} from '@angular/router';
import {ManagerUserAccessPeriodProfileDaoService} from './kluh-manager/main/manager-user-access-period/manager-user-access-period-profile-dao.service';
import {MarketplaceOrderService} from './kluh-manager/main/marketplace/marketplace-order/marketplace-order.service';
import {USER_TOPIC} from '../main';
import {R2CloudStompService} from './kluh-manager/ws/r2-cloud-stomp.service';
import {ValueWrapper} from './kluh-manager/helpers/value-wrapper';
import {R2MenuService} from './kluh-manager/utils/r2-menu.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    private loginTouched = false;
    private timerCheckAccessDenied: NodeJS.Timer;
    // private timerCheckMarketplaceOrder: NodeJS.Timer;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private authService: AuthService,
        private r2MenuService: R2MenuService,
        private userAuthorityDao: UserAuthorityDaoService,
        private desktopServerService: DesktopServerService,
        private router: Router,
        private managerUserAccessPeriodProfileDaoService: ManagerUserAccessPeriodProfileDaoService,
        private r2CloudStompService: R2CloudStompService,
        private marketplaceOrderService: MarketplaceOrderService,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // Get default navigation
        this.navigation = navigation;
        this.router.events.pipe(takeUntil(this._unsubscribeAll), filter((event => event instanceof NavigationEnd))).subscribe((event: NavigationEnd) => {
            if (event.url.startsWith('/login')) {
                this.loginTouched = true;
            }
        });

        this._fuseNavigationService.register('main', this.navigation);

        this.authService.isLoggedIn().pipe(switchMap((value: boolean) => {
            if (value) {
                return this.userAuthorityDao.getMe().pipe(take(1), map((result: FirebaseUserDetails) => {
                    return [result, value];
                }));
            } else {
                return of([null, value] as [FirebaseUserDetails | null, boolean]);
            }
        })).subscribe((result: [FirebaseUserDetails | null, boolean]) => {
            const me = result[0];
            const isLoggedIn = result[1];
            if (me && isLoggedIn) {
                this.r2CloudStompService.stompTopic<ValueWrapper>(`${USER_TOPIC}/web-quit`).subscribe((resultStompTopic) => {
                    this.authService.forcedLogout(resultStompTopic.value);
                });
                this.checkAccessDenied();
                this.timerCheckAccessDenied = setInterval(() => {
                    this.checkAccessDenied();
                }, 300000);

                if (this.loginTouched) {
                    setTimeout(() => {
                        this.setNavBarAndToolBarHidden(false);
                    }, 500);
                } else {
                    this.setNavBarAndToolBarHidden(false);
                }
                this.desktopServerService.initSubscribe();

                this.marketplaceOrderService.getAmountOfUnreadOrdersAndPutOnMenuBadge();
                // this.timerCheckMarketplaceOrder = setInterval(() => {
                //     this.marketplaceOrderService.getAmountOfUnreadOrdersAndPutOnMenuBadge();
                // }, 30000);

            } else {

                if (this.timerCheckAccessDenied) {
                    clearInterval(this.timerCheckAccessDenied);
                }
                if (this.timerCheckAccessDenied) {
                    clearInterval(this.timerCheckAccessDenied);
                }

                this.desktopServerService.ngOnDestroy();

                this.setNavBarAndToolBarHidden(true);

                if (isLoggedIn) {
                    this.authService.signOut().subscribe();
                }
            }
        });


        this._fuseNavigationService.onNavigationChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((menu) => {
            if (menu) {
                if (isMacOS()) {
                    this._fuseNavigationService.updateNavigationItem('desktop', {
                        hidden: true
                    });
                }
                this.r2MenuService.innitVisibleMenu();
            }
        });


        // Add languages
        this._translateService.addLangs(['en', 'pt-br']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish);

        // Use a language
        this._translateService.use('pt-br');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }
    }

    //
    // @HostListener('window:beforeunload', ['$event'])
    // handleClose(event: Event): void {
    //     console.log('window:beforeunload');
    //     event.returnValue = false;
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                if (this.document && this.document.body.classList) {
                    for (let i = 0; i < this.document.body.classList.length; i++) {
                        const className = this.document.body.classList[i];

                        if (className.startsWith('theme-')) {
                            this.document.body.classList.remove(className);
                        }
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    private setNavBarAndToolBarHidden(value: boolean): void {
        this._fuseConfigService.setConfig({
            layout: {
                navbar: {
                    hidden: value
                },
                toolbar: {
                    hidden: value
                }
            }
        });
    }

    private checkAccessDenied(): void {
        this.managerUserAccessPeriodProfileDaoService.canAccess().subscribe((canAccess) => {
            if (canAccess) {
                this.authService.processCanAccess(canAccess);
                // console.debug('canAccess: ' + canAccess[0] + ' | ' + canAccess[1] + ' | ' + canAccess[2]);
            }
        });
    }
}
