import {FuseNavigation} from '@fuse/types';
import {faBuilding, faChartLine, faDesktop, faFile, faPlug, faPrint, faUsers, faUserShield} from '@fortawesome/free-solid-svg-icons';
import {faSuperpowers} from '@fortawesome/free-brands-svg-icons';
import {faBrush} from '@fortawesome/free-solid-svg-icons/faBrush';

export const navigation: FuseNavigation[] = [
    {
        'id': 'applications',
        'title': 'Cadastro',
        'type': 'group',
        'children': [
            {
                'id': 'register',
                'title': 'Empresa, filiais e pessoas',
                'type': 'item',
                'faIcon': faBuilding,
                'url': '/register',
                'permissions': [
                    'Customer.AUDIT'
                ]
            },
            {
                'id': 'r2cloud-admin',
                'title': 'Administração de projetos',
                'type': 'item',
                'icon': 'dashboard',
                'url': '/admin',
                'permissions': ['SubProject.READ']
            },
            {
                'id': 'r2cloud-users-pool',
                'title': 'Usuários do Pool',
                'type': 'item',
                'faIcon': faUsers,
                'url': '/users-pool',
                'permissions': ['ADUserPool.CREATE']
            }]
    },
    {
        'id': 'applications2',
        'title': 'Menu',
        'type': 'group',
        'children': [
            {
                'id': 'r2cloud-support',
                'title': 'Busca avançada',
                'type': 'item',
                'icon': 'add_to_queue',
                'url': '/support',
                'permissions': ['DesktopServer.READ']
            },
            {
                'id': 'desktop',
                'title': 'Desktop',
                'type': 'item',
                'faIcon': faDesktop,
                'url': '/desktop'
            },
            {
                'id': 'webclient-html5',
                'title': 'Desktop HTML5',
                'type': 'item',
                'faIcon': faDesktop,
                'url': '/webclient-html5'
            },
            {
                'id': 'my-prints',
                'title': 'Meus impressos',
                'type': 'item',
                'faIcon': faPrint,
                'url': '/my-prints'
            },
            {
                'id': 'my-docs',
                'title': 'Meus arquivos',
                'type': 'item',
                'faIcon': faFile,
                'url': '/my-docs'
            },
            {
                'id': 'statistics',
                'title': 'Estatísticas',
                'type': 'item',
                'faIcon': faChartLine,
                'url': '/statistics',
                'permissions': ['CustomerGroup.AUDIT']
            },
            {
                'id': 'custom-layout',
                'title': 'Personalizar Layout',
                'type': 'item',
                'faIcon': faBrush,
                'url': '/custom-layout',
                'permissions': ['CustomerGroup.WRITE']
            }
        ]
    },
    {
        'id': 'marketplace',
        'title': 'Marketplace',
        'type': 'group',
        'permissions': ['MarketplaceProduct.AUDIT'],
        'children': [
            {
                'id': 'services',
                'title': 'Serviços',
                'type': 'item',
                'icon': 'room_service',
                'url': '/marketplace',
            },
            {
                'id': 'orders',
                'title': 'Pedidos',
                'type': 'item',
                'icon': 'assignment',
                'url': '/marketplace-order',
            }
        ]
    },
    {
        'id': 'partners',
        'title': 'Partners',
        'type': 'group',
        'children': [{
            'id': 'bulk-update',
            'title': 'Atualização em massa',
            'type': 'item',
            'icon': 'update',
            'url': '/partners/hipcom/hipcom-bulk-update',
            'permissions': ['HipcomBulkUpdateDetail.WRITE', 'HipcomBulkUpdateDetail.CREATE']
        }, {
            'id': 'customer-groups',
            'title': 'Lista de empresa',
            'type': 'item',
            'icon': 'assignment',
            'url': '/customer-groups',
            'permissions': ['HipcomBulkUpdateDetail.AUDIT']
        }]
    },
    {
        'id': 'registration',
        'title': 'Admin',
        'type': 'group',
        'roles': ['ROLE_DEV_ADMIN'],
        'children': [

            // {
            //     'id': 'support',
            //     'title': 'Infra-estrutura',
            //     'type': 'item',
            //     'icon': 'build',
            //     'url': '/infra',
            //     'permissions': ['Instance.WRITE', 'Instance.CREATE']
            // },
            {
                'id': 'status',
                'title': 'Status',
                'type': 'item',
                'icon': 'insert_emoticon',
                'url': '/status',
                'permissions': ['ADUserPool.AUDIT']
            },
            {
                'id': 'manager-user-permissions',
                'title': 'Super Admin',
                'type': 'item',
                'faIcon': faSuperpowers,
                'url': '/manager-user-permissions',
                'permissions': ['ManagerUserPermissions.WRITE']
            },
            {
                'id': 'iam',
                'title': 'IAM Papéis',
                'type': 'item',
                'faIcon': faUserShield,
                'url': '/iam-roles',
                'permissions': ['ADUserPool.AUDIT']
            },
            {
                'id': 'server-plugin',
                'title': 'Plugins de Servidor',
                'type': 'item',
                'faIcon': faPlug,
                'url': '/server-plugin',
                'permissions': ['DotNetServerPlugin.WRITE']
            }
        ]
    },
];
