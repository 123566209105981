import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ClientStatus, R2CloudClientStatus} from '../models';
import {faCircle, faExclamationTriangle, faLaptopCode} from '@fortawesome/free-solid-svg-icons';
import {R2CloudClientService} from '../ws/r2-cloud-client.service';
import {ComponentCleaner} from '../component-cleaner';
import {fuseAnimations} from '../../../@fuse/animations';
import {ClientDownloadService} from '../main/desktop/client-download.service';
import {isMacOS} from '../helpers/kluh';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../helpers/confirm-dialog/confirm-dialog.component';
import {ValueWrapper} from '../helpers/value-wrapper';
import {R2CloudStompService} from '../ws/r2-cloud-stomp.service';
import {R2CloudHttpApiService} from '../r2-cloud-http-api.service';
import {debounce, map, startWith, takeUntil} from 'rxjs/operators';
import {fromEvent} from 'rxjs/internal/observable/fromEvent';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {timer} from 'rxjs/internal/observable/timer';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {CustomLayoutService} from '../main/custom-layout/custom-layout.service';

@Component({
    selector: 'app-client-status-toolbar',
    templateUrl: './client-status-toolbar.component.html',
    styleUrls: ['./client-status-toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ClientStatusToolbarComponent extends ComponentCleaner implements OnInit, OnDestroy {
    faCircle = faCircle;
    clientStatus: ClientStatus;
    R2CloudClientStatus = R2CloudClientStatus;
    connectedOnce = false;
    showErrorMessage = false;
    timeUntilErrorMessageAppears = 15000;
    modalAlertError: MatDialogRef<any> = null;

    constructor(
        private clientService: R2CloudClientService,
        private clientDownload: ClientDownloadService,
        private stomp: R2CloudStompService,
        private dialog: MatDialog,
        private customLayoutService: CustomLayoutService,
        private http: R2CloudHttpApiService
    ) {
        super();
        this.delayedErrorMessage();

        this.clientService.clientConnectionChanges().pipe(takeUntil(this.onDestroy$)).subscribe((status) => {
            // console.log('received client status', status);
            this.onClientStatus(status);
        });
        this.clientService.startClientListener();
    }

    ngOnDestroy(): void {
        console.debug('ClientStatusToolbarComponent on destroy');
        super.ngOnDestroy();
    }

    ngOnInit(): void {

    }

    private delayedErrorMessage(): void {
        const visibilityChange$ = fromEvent(document, 'visibilitychange')
            .pipe(startWith({} as Event), map(() => document.hidden),
                debounce((x: boolean) => {
                    if (x) {
                        return timer(0);
                    } else {
                        return timer(this.timeUntilErrorMessageAppears);
                    }
                }));
        const wrapper$ = this.clientService.clientConnectionChanges().pipe(
            map((v) => v.status),
            map((status) => {
                return {
                    isError: status !== R2CloudClientStatus.CONNECTED && status !== R2CloudClientStatus.CLIENT_UPDATE,
                    status: status
                } as ClientStatusWrapper;
            }),
            debounce((v) => {
                if (v.isError) {
                    return timer(this.timeUntilErrorMessageAppears);
                } else {
                    return timer(0);
                }
            }));
        combineLatest([
            wrapper$,
            visibilityChange$,
        ])
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((status) => {
                const hidden = status[1];
                const wrapper = status[0];
                this.showErrorMessage = !hidden && wrapper.isError;
                if (this.showErrorMessage) {
                    this.alertError(wrapper.status);
                } else {
                    this.closeAlertError();
                }
            });
    }

    private onClientStatus(result: ClientStatus): void {
        const clientStatus = result;
        if (clientStatus.status === R2CloudClientStatus.CONNECTED) {
            if (clientStatus.webClientId !== this.stomp.webClientId) {
                this.http.post<ValueWrapper>(`manager-users/disconnect-windows-client/${clientStatus.clientId}`, null).subscribe(() => {
                    },
                    (error) => {
                        console.error(error);
                    });
            }
            this.connectedOnce = true;
        }
        if (this.clientStatus && this.clientStatus.status === R2CloudClientStatus.CLIENT_UPDATE && clientStatus.status === R2CloudClientStatus.NOT_CONNECTED) {
            return;
        }
        if (this.connectedOnce && this.clientStatus && clientStatus.status === R2CloudClientStatus.NOT_INSTALLED) {
            this.clientStatus.status = R2CloudClientStatus.NOT_CONNECTED;
        } else {
            this.clientStatus = clientStatus;
        }
    }



    downloadLatestVersion(): void {
        this.clientDownload.download();
    }

    isMac(): boolean {
        return isMacOS();
    }

    alertError(status: R2CloudClientStatus): void {
        if (!this.modalAlertError) {
            let message: string;
            let buttonValue: string;
            let cancelButtonValue: string | null = null;
            if (status === R2CloudClientStatus.STOMP_NOT_CONNECTED) {
                message = 'Não é possivel conectar ao servidor.';
                buttonValue = 'Tentar novamente';
            } else {
                if (this.connectedOnce) {
                    message = 'Para reativar a tela clique no botão abaixo.';
                    buttonValue = 'Reativar';
                } else {
                    message = 'Agente não detectado.\nÉ possivel tentar abri-lo novamente ou abra o instalador clicando em Download.';
                    cancelButtonValue = 'Tentar Abrir';
                    buttonValue = 'Download';
                }
            }
            this.modalAlertError = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: message,
                    disableCancel: cancelButtonValue === null,
                    confirmButtonValue: buttonValue,
                    cancelButtonValue: cancelButtonValue,
                    icon: 'info_outline'
                }
            });
            this.modalAlertError?.afterClosed().subscribe((result) => {
                this.modalAlertError = null;
                console.log('status: ', status);
                if (status === R2CloudClientStatus.STOMP_NOT_CONNECTED) {
                    window.location.reload();
                } else if ((status === R2CloudClientStatus.INSTALLED || status === R2CloudClientStatus.NOT_INSTALLED) && !result) {
                    this.clientService.startClientClick({isTrusted: true} as Event);
                } else {
                    if (!this.connectedOnce) {
                        if (result) {
                            this.downloadLatestVersion();
                        }
                    }
                }
            });
        }
    }

    closeAlertError(): void {
        this.modalAlertError?.close();
        this.modalAlertError = null;
    }

    get agentIconClass(): string {
        if (!this.clientStatus || !this.clientStatus.status) {
            return '';
        }
        switch (this.clientStatus.status) {
            case R2CloudClientStatus.CONNECTED:
                return 'client-connected';
            case R2CloudClientStatus.CLIENT_UPDATE:
                return 'client-update';
            case R2CloudClientStatus.NOT_CONNECTED:
                return 'client-not-connected';
            case R2CloudClientStatus.INSTALLED:
                return 'client-installed';
            case R2CloudClientStatus.NOT_INSTALLED:
                return 'client-not-installed';
            case R2CloudClientStatus.STOMP_NOT_CONNECTED:
                return 'client-stomp-not-connected';
            case R2CloudClientStatus.OPENING:
                return 'client-opening';
        }
    }

    get agentIcon(): IconDefinition {
        if (!this.clientStatus || !this.clientStatus.status) {
            return faCircle;
        }
        switch (this.clientStatus.status) {
            case R2CloudClientStatus.CONNECTED:
                return faCircle;
            case R2CloudClientStatus.CLIENT_UPDATE:
                return faLaptopCode;
            case R2CloudClientStatus.NOT_CONNECTED:
                return faLaptopCode;
            case R2CloudClientStatus.INSTALLED:
                return faCircle;
            case R2CloudClientStatus.NOT_INSTALLED:
                return faLaptopCode;
            case R2CloudClientStatus.STOMP_NOT_CONNECTED:
                return faExclamationTriangle;
            case R2CloudClientStatus.OPENING:
                return faLaptopCode;
        }
    }

    get agentTooltip(): string {
        if (!this.clientStatus || !this.clientStatus.status) {
            return '';
        }
        switch (this.clientStatus.status) {
            case R2CloudClientStatus.CONNECTED:
                return 'Agente conectado';
            case R2CloudClientStatus.CLIENT_UPDATE:
                return 'Agente sendo atualizado';
            case R2CloudClientStatus.NOT_CONNECTED:
                return 'Agente desconectado';
            case R2CloudClientStatus.INSTALLED:
                return 'Agente instalado';
            case R2CloudClientStatus.NOT_INSTALLED:
                return 'Agente não instalado. (Clique para fazer o Download)';
            case R2CloudClientStatus.STOMP_NOT_CONNECTED:
                return 'Servidor desconectado';
            case R2CloudClientStatus.OPENING:
                return 'Agente abrindo';
        }
    }

    get agentStatusCursor(): string {
        if (!this.clientStatus || !this.clientStatus.status) {
            return 'default';
        }
        switch (this.clientStatus.status) {
            case R2CloudClientStatus.CONNECTED:
                return 'default';
            case R2CloudClientStatus.CLIENT_UPDATE:
                return 'default';
            case R2CloudClientStatus.NOT_CONNECTED:
                return 'pointer';
            case R2CloudClientStatus.INSTALLED:
                return 'pointer';
            case R2CloudClientStatus.NOT_INSTALLED:
                return 'pointer';
            case R2CloudClientStatus.STOMP_NOT_CONNECTED:
                return 'pointer';
            case R2CloudClientStatus.OPENING:
                return 'pointer';
        }
    }

    agentStatusClick(e: Event): void {
        if (!this.clientStatus || !this.clientStatus.status) {
            return;
        }
        switch (this.clientStatus.status) {
            case R2CloudClientStatus.CONNECTED:
                return;
            case R2CloudClientStatus.CLIENT_UPDATE:
                return;
            case R2CloudClientStatus.NOT_CONNECTED:
                return this.clientService.startClientClick(e);
            case R2CloudClientStatus.INSTALLED:
                return this.clientService.startClientClick(e);
            case R2CloudClientStatus.NOT_INSTALLED:
                return this.downloadLatestVersion();
            case R2CloudClientStatus.STOMP_NOT_CONNECTED:
                return window.location.reload();
            case R2CloudClientStatus.OPENING:
                return this.clientService.startClientClick(e);
        }
    }
}

interface ClientStatusWrapper {
    isError: boolean;
    status: R2CloudClientStatus;
}
