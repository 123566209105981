import {Component, OnInit} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ComponentCleaner} from '../../../component-cleaner';
import {distinctUntilChanged, filter, map, startWith} from 'rxjs/operators';
import {ADUser, ADUserPoolRelationship, CustomerGroup, ManagerUser, Project, ProjectSharedInfo, SubProject} from '../../../models';
import {SubProjectCreateEditComponent} from './sub-project-create-edit/sub-project-create-edit.component';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {R2CloudAdminService} from '../r2-cloud-admin.service';
import {SubProjectDaoService} from './sub-project-dao.service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {AdDomainDaoService} from '../../ad-domain/ad-domain-dao.service';
import {AdGroupDaoService} from '../../ad-group/ad-group-dao.service';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';
import {DesktopServerDaoService} from '../../desktop-server/desktop-server-dao.service';
import {LinuxServerDaoService} from '../../linux-server/linux-server-dao.service';
import {RemoteAppDaoService} from '../../remote-app/remote-app-dao.service';
import {CustomerDaoService} from '../../customer/customer-dao.service';
import {FormControl} from '@angular/forms';
import {forkJoin, Observable} from 'rxjs';
import {SubProjectDialogComponent} from './sub-project-dialog/sub-project-dialog.component';
import {ProjectDaoService} from '../../project/project-dao.service';
import {RoleSubProjectManagerUserDAOService} from '../../iam/role-sub-project-manager-user-dao.service';
import {RoleDAOService} from '../../iam/role-dao.service';
import {ActivatedRoute, Router} from '@angular/router';
import {QueryParamsService} from '../../../query-params.service';
import {AdAdminDaoService} from '../../ad-admin/ad-admin.dao.service';
import * as _ from 'lodash';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {MatDialog} from '@angular/material/dialog';
import {AdUserServiceExternalDaoService} from '../../ad-user-service-external/ad-user-service-external-dao.service';
import {AdUserPoolDaoService} from '../../ad-user-pool/ad-user-pool-dao.service';
import {AdUserPoolRelationshipDaoService} from '../../ad-user-pool-relationship/ad-user-pool-relationship-dao.service';

@Component({
    selector: 'app-r2-cloud-admin-sub-project',
    templateUrl: './r2-cloud-admin-sub-project.component.html',
    styleUrls: ['./r2-cloud-admin-sub-project.component.scss'],
    animations: fuseAnimations
})
export class R2CloudAdminSubProjectComponent extends ComponentCleaner implements OnInit {
    subProjects: SubProject[];
    projectSharedInfo: ProjectSharedInfo;
    sharedProjects: Project[];
    subProjectFilterControl: FormControl = new FormControl();
    filteredSubProjects: Observable<SubProject[]>;
    customerGroup: CustomerGroup;
    hasCreatePermission: boolean;
    appCanCreateList: PermissionWrapper[];

    constructor(public customerGroupService: CustomerGroupService,
                public adminService: R2CloudAdminService,
                public subProjectDao: SubProjectDaoService,
                private projectDao: ProjectDaoService,
                private adAdminDaoService: AdAdminDaoService,
                private dialog: MatDialog,
                private managerUserDao: ManagerUserDaoService,
                private roleDao: RoleDAOService,
                private roleSubProjectManagerUserDao: RoleSubProjectManagerUserDAOService,
                private adDomainDao: AdDomainDaoService,
                private queryParamsService: QueryParamsService,
                private adGroupDao: AdGroupDaoService,
                private adUserDaoService: AdUserDaoService,
                private adUserPoolDaoService: AdUserPoolDaoService,
                private adUserPoolRelationshipDaoService: AdUserPoolRelationshipDaoService,
                private adUserServiceExternalDaoService: AdUserServiceExternalDaoService,
                private desktopServerDao: DesktopServerDaoService,
                private linuxServerDao: LinuxServerDaoService,
                private remoteAppDao: RemoteAppDaoService,
                private route: ActivatedRoute,
                private router: Router,
                private customerDao: CustomerDaoService) {
        super();
        this.appCanCreateList = [
            {type: 'SubProject', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.get()},
        ];
    }

    ngOnInit(): void {
        this.addSubscription(this.customerGroupService.get().subscribe((customerGroup) => {
            if (!this.customerGroup || typeof this.customerGroup === 'undefined') {
                if (customerGroup && customerGroup.id) {
                    this.customerGroup = customerGroup;
                    this.loadSubProjects(customerGroup);
                }
            } else if (customerGroup && customerGroup.id && this.customerGroup.id !== customerGroup.id) {
                this.customerGroup = customerGroup;
                this.loadSubProjects(customerGroup);
            }

        }));
        this.addSubscription(this.adminService.subProject$.subscribe((subProject) => {
            this.onSubProjectLoaded(subProject);
        }));
        this.filteredSubProjects = this.subProjectFilterControl.valueChanges.pipe(
            startWith(''),
            map((value: SubProject | string) => {
                if (this.subProjects) {
                    if (value) {
                        return this.subProjects.filter((subProject) => {
                            let myFilter;
                            if (typeof value === 'object') {
                                myFilter = value.name.toLowerCase();
                            } else {
                                myFilter = value.toLowerCase();
                            }
                            return subProject.name.toLowerCase().indexOf(myFilter) !== -1;
                        });
                    } else {
                        return this.subProjects;
                    }
                } else {
                    return [];
                }
            })
        );
        this.subProjectFilterControl.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((subProject) => {
                if (subProject && subProject.id) {
                    this.loadSubProject(subProject);
                } else {
                    this.unloadSubProject();
                }
            });
    }

    private loadSubProjects(customerGroup: CustomerGroup): void {
        this.unloadSubProject();
        this.subProjectDao.filter({'customerGroupId': customerGroup.id}).subscribe((subProjects) => {
            if (subProjects) {
                this.subProjects = subProjects;
            } else {
                this.subProjects = [];
            }
            this.loadProjects(customerGroup);
            const queryParamMap = this.route.snapshot.queryParamMap;
            if (queryParamMap.has('subProjectId')) {
                const subProjectId: number = Number(queryParamMap.get('subProjectId'));
                const index = this.subProjects.findIndex((x) => x.id === subProjectId);
                if (index > -1) {
                    this.subProjectFilterControl.setValue(this.subProjects[index]);
                } else {
                    this.subProjectFilterControl.setValue(this.subProjects[0]);
                }
            } else {
                this.subProjectFilterControl.setValue(this.subProjects[0]);
            }


        });
    }


    private loadProjects(customerGroup: CustomerGroup): void {
        this.sharedProjects = [];
        this.projectDao.filter({'customerGroupId': customerGroup.id}).subscribe((projects) => {
            if (projects) {
                if (projects.length < 1) {
                    this.onCreateSubProject();
                } else {
                    for (let x = 0; x < projects.length; x++) {
                        if (projects[x].hasSubProjects) {
                            this.sharedProjects.push(projects[x]);
                        }
                        this.adminService.addProject(projects[x]);
                    }
                }
                this.findProjectForProjectSharedInfoInProjectList();
            } else {
                this.onCreateSubProject();
            }
        });
    }


    findProjectForProjectSharedInfoInProjectList(): void {
        this.projectSharedInfo = null;
        if (this.sharedProjects) {
            const index = this.sharedProjects.findIndex((o) => o.id === this.adminService.subProject.projectId);
            if (index > -1) {
                this.getProjectSharedInfo(this.sharedProjects[index], this.adminService.subProject);
            }
        }
    }


    getProjectSharedInfo(project: Project, subProject: SubProject): void {
        if (project.hasSubProjects) {
            this.projectDao.getProjectSharedInfo(project.id, subProject.id).subscribe((projectSharedInfo) => {
                this.projectSharedInfo = projectSharedInfo;
            });
        }
    }
    private loadSubProject(subProject: SubProject): void {
        this.adminService.subProject = subProject;
        this.findProjectForProjectSharedInfoInProjectList();
        this.adDomainDao.filter({'subProjectId': subProject.id}).subscribe((adDomains) => {
            if (adDomains) {
                this.adminService.adDomains = adDomains;
            } else {
                this.adminService.adDomains = [];
            }
            if (this.adminService.adDomains.length > 0) {
                const adDomainIds = Object.values(adDomains).map(adDomain => {
                    return adDomain.id;
                });
                this.adUserPoolDaoService.findAllByADDomainIds(adDomainIds.toString()).subscribe((adUsersPool) => {
                    if (adUsersPool) {
                        this.adminService.adUsersPool = adUsersPool.filter((adUserPool) => {
                            return adUserPool.serviceUser === false;
                        });
                        this.adminService.adUsersPoolService = adUsersPool.filter((adUserPool) => {
                            return adUserPool.serviceUser === true;
                        });
                    } else {
                        this.adminService.adUsersPool = [];
                    }
                });
                this.desktopServerDao.filter({'subProjectId': subProject.id}).subscribe((desktopServers) => {
                    if (desktopServers) {
                        this.adminService.desktopServers = desktopServers;

                        const desktopServerIds: number[] = [];
                        for (const desktopServer of this.adminService.desktopServers) {
                            desktopServerIds.push(desktopServer.id);
                        }
                        this.desktopServerDao.getDesktopServerIdWithVersion(desktopServerIds).subscribe((desktopServerIdWithVersionList) => {
                            if (desktopServerIdWithVersionList) {
                                this.adminService.desktopServerIdWithVersions = desktopServerIdWithVersionList;
                            }
                        });

                    } else {
                        this.adminService.desktopServers = [];
                    }
                    this.adminService.adDomains.forEach(adDomain => {
                        this.adminService.desktopServers.findIndex((y) => y.id === adDomain.adServerId);
                    });
                });
            }
        });

        this.linuxServerDao.filter({'subProjectId': subProject.id}).subscribe((linuxServers) => {
            if (linuxServers) {
                this.adminService.linuxServers = linuxServers;
            } else {
                this.adminService.linuxServers = [];
            }
        });

        this.adGroupDao.filter({subProjectId: subProject.id}).subscribe((adGroups) => {
            if (adGroups) {
                this.adminService.adGroups = adGroups;
            } else {
                this.adminService.adGroups = [];
            }
        });
        const myAdUsers$ = this.adUserDaoService.getMyAdUsers();
        const adUsers$ = this.adUserDaoService.filter({subProjectId: subProject.id, serviceUser: false});
        const adUsersPoolRelationshipAll$ = this.adUserPoolRelationshipDaoService.filter({subProjectId: subProject.id});
        const managerUsers$ = this.managerUserDao.filter({subProjectId: subProject.id});
        const managerUsersAdministrator$ = this.managerUserDao.filter({administratorsInSubProjectId: subProject.id});
        const managerUserOfADUserPoolBySubProjectIds$ = this.managerUserDao.findAllManagerUserOfADUserPoolBySubProjectId(subProject.id);

        forkJoin([myAdUsers$, adUsers$, adUsersPoolRelationshipAll$, managerUsers$, managerUsersAdministrator$, managerUserOfADUserPoolBySubProjectIds$]).subscribe((
            result: [ADUser[], ADUser[], ADUserPoolRelationship[], ManagerUser[], ManagerUser[], ManagerUser[]]) => {
            const myAdUsers = result[0];
            const adUsers = result[1];
            const adUsersPoolRelationshipAll = result[2];
            const managerUsers = result[3];
            const managerUsersAdministrator = result[4];
            const managerUserOfADUserPoolBySubProjectIds = result[5];

            if (myAdUsers) {
                this.adminService.myAdUsers = myAdUsers;
            } else {
                this.adminService.myAdUsers = [];
            }

            if (adUsers) {
                this.adminService.adUsers = adUsers;
            } else {
                this.adminService.adUsers = [];
            }

            if (adUsersPoolRelationshipAll) {
                this.adminService.adUsersPoolRelationship = adUsersPoolRelationshipAll;
            } else {
                this.adminService.adUsersPoolRelationship = [];
            }

            if (managerUsers) {
                this.adminService.managerUsers = managerUsers;
            } else {
                this.adminService.managerUsers = [];
            }

            if (managerUserOfADUserPoolBySubProjectIds) {
                managerUserOfADUserPoolBySubProjectIds.forEach(manageUser => {
                    this.adminService.addOrReplaceManagerUser(manageUser);
                });
            }

            if (managerUsersAdministrator) {
                this.adminService.managerUsersAdministrator = managerUsersAdministrator;
            } else {
                this.adminService.managerUsersAdministrator = [];
            }

            const adUserIdList = _.unionBy(this.adminService.adUsers, this.adminService.myAdUsers, 'id').map((o) => o.id);
            this.adAdminDaoService.findByADUserList(adUserIdList).subscribe((adAdmins) => {
                if (adAdmins) {
                    this.adminService.adAdmins = adAdmins;
                } else {
                    this.adminService.adAdmins = [];
                }
            });
        });

        this.adUserDaoService.filter({subProjectId: subProject.id, serviceUser: true}).subscribe((adUsers) => {
            if (adUsers) {
                this.adminService.adUsersService = adUsers;
            } else {
                this.adminService.adUsersService = [];
            }
        });

        this.adUserServiceExternalDaoService.findAllBySubProjectId(subProject.id).subscribe((adUserServiceExternals) => {
            if (adUserServiceExternals) {
                this.adminService.adUserServiceExternals = adUserServiceExternals;
            } else {
                this.adminService.adUserServiceExternals = [];
            }
        });

        this.remoteAppDao.filter({subProjectId: subProject.id}).subscribe((remoteApps) => {
            if (remoteApps) {
                this.adminService.remoteApps = remoteApps;
            } else {
                this.adminService.remoteApps = [];
            }
        });


        this.roleSubProjectManagerUserDao.getByParentId(subProject.id).subscribe((roleSubProjectManagerUserList) => {
            if (roleSubProjectManagerUserList) {
                this.adminService.roleSubProjectManagerUsers = roleSubProjectManagerUserList;
            } else {
                this.adminService.roleSubProjectManagerUsers = [];
            }
        });


        this.roleDao.get().subscribe((roles) => {
            if (roles) {
                this.adminService.roles = roles;
            } else {
                this.adminService.roles = [];
            }
        });


        if (subProject.customerGroupId) {
            this.customerDao.filter({customerGroupId: subProject.customerGroupId}).subscribe((customers) => {
                if (customers) {
                    this.adminService.customers = customers;
                } else {
                    this.adminService.customers = [];
                }
                if (this.adminService.customers && this.adminService.customers.length < 1) {
                    this.queryParamsService.setCustomUrlQueryParam('/register/',
                        {queryParams: {id: this.customerGroupService.customerGroup.id.toString()}});
                }
            });
        }

    }

    private unloadSubProject(): void {
        this.subProjectFilterControl.setValue('');
        this.adminService.unload();
        this.hasCreatePermission = false;
    }

    public onSubProjectLoaded(subProject: SubProject): void {
        const index = this.subProjects.findIndex((o) => o.id === subProject.id);

        if (index > -1) {
            this.subProjects[index] = subProject;
            if (this.subProjectFilterControl.value.id === subProject.id) {
                this.subProjectFilterControl.setValue(this.subProjects[index]);
            }
        }
    }

    onCreateSubProject(): void {
        const metricTypeDialogRef = this.dialog.open(SubProjectDialogComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large'
        });
        this.addSubscription(metricTypeDialogRef.afterClosed().subscribe((choice) => {
            let dialogRef;
            let subscription;
            switch (choice) {
                case 'private':
                    dialogRef = this.dialog.open(SubProjectCreateEditComponent, {
                        disableClose: true,
                        panelClass: 'generic-edit-dialog',
                        data: {
                            customerGroup: this.customerGroup,
                            projectType: 'private'
                        }
                    });
                    if (dialogRef) {
                        subscription = this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((savedSubProject) => {
                            this.subProjects.push(savedSubProject);
                            this.adminService.subProject = savedSubProject;
                            this.subProjectFilterControl.setValue(savedSubProject);
                        }));
                        this.addSubscription(subscription);
                    }
                    break;
                case 'shared':
                    dialogRef = this.dialog.open(SubProjectCreateEditComponent, {
                        disableClose: true,
                        panelClass: 'generic-edit-dialog',
                        data: {
                            customerGroup: this.customerGroup,
                            projectType: 'shared',
                            projects: this.sharedProjects
                        }
                    });
                    if (dialogRef) {
                        subscription = this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((savedSubProject) => {
                            this.subProjects.push(savedSubProject);
                            this.adminService.subProject = savedSubProject;
                            this.subProjectFilterControl.setValue(savedSubProject);
                        }));
                        this.addSubscription(subscription);
                    }
                    break;
                case 'new-shared':
                    dialogRef = this.dialog.open(SubProjectCreateEditComponent, {
                        disableClose: true,
                        panelClass: 'generic-edit-dialog',
                        data: {
                            customerGroup: this.customerGroup,
                            projectType: 'new-shared'
                        }
                    });
                    if (dialogRef) {
                        subscription = this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((savedSubProject) => {
                            this.loadProjects(this.customerGroup);
                            this.subProjects.push(savedSubProject);
                            this.adminService.subProject = savedSubProject;
                            this.subProjectFilterControl.setValue(savedSubProject);
                        }));
                        this.addSubscription(subscription);
                    }
                    break;
            }
        }));
    }

    onEditSubProject(subProject: SubProject): void {
        this.openSubProjectModal(subProject);
    }

    openSubProjectModal(subProject: SubProject): void {
        const projectId: number = this.subProjects[0].projectId;
        const customerGroupId: number = this.subProjects[0].customerGroupId;
        const dialogRef = this.dialog.open(SubProjectCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                subProject: subProject,
                projectId: projectId,
                customerGroupId: customerGroupId
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((savedSubProject) => {
            this.adminService.subProject = savedSubProject;
        });
        this.addSubscription(subscription);
    }
}
