import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {DesktopServerDaoService} from '../desktop-server-dao.service';
import {DesktopServer} from '../../../models';
import {convertToFormGroup} from '../../../helpers/kluh';
import {ValueWrapper} from '../../../helpers/value-wrapper';
import {MatDialogRef} from '@angular/material/dialog';
import {DomainPathService} from '../../../domain-path/domain-path.service';

@Component({
    selector: 'app-desktop-server-create-token',
    templateUrl: './desktop-server-create-token.component.html',
    styleUrls: ['./desktop-server-create-token.component.scss']
})
export class DesktopServerCreateTokenComponent implements OnInit {


    myForm: FormGroup;
    token: ValueWrapper;
    script: string;
    copied: string;
    desktopServer: DesktopServer = null;

    constructor(public dialogRef: MatDialogRef<DesktopServerCreateTokenComponent>,
                public adminService: R2CloudAdminService,
                private fb: FormBuilder,
                private desktopServerDaoService: DesktopServerDaoService,
                private domainPathService: DomainPathService,
    ) {
    }

    ngOnInit(): void {

        this.desktopServer = {
            disableUpdate: false,
            adDomainId: null,
            clientId: '',
            publicIp: '',
            privateIp: '',
            hostname: '',
            connectionFQDN: '',
            firewallFQDNIds: [],
            connectionPort: null,
            id: null,
            comment: '',
            active: true,
            remoteAppIds: [],
            gatewayFQDN: null,
            serverPluginTemplateIds: [],
            modified: null,
            optlock: null
        };
        this.myForm = this.fb.group(convertToFormGroup(this.desktopServer));
        if (this.adminService.adDomains && this.adminService.adDomains.length === 1) {
            this.desktopServer.adDomainId = this.adminService.adDomains[0].id;
            this.generateInstallToken();
        }
        this.myForm.get('adDomainId').valueChanges.subscribe((adDomainId) => {
            this.desktopServer.adDomainId = adDomainId;
            this.generateInstallToken();
        });
    }

    generateInstallToken(): void {
        if (!this.desktopServer.adDomainId) {
            throw new Error('Invalid adDomain Id');
        }
        this.desktopServerDaoService.generateInstallToken(this.desktopServer, this.adminService.subProject.id).subscribe((token) => {
            if (token) {
                this.token = token;
                this.script =
                    `. { iwr -useb ${this.domainPathService.staticURL}script/r2cloud-windows-server-agent-install-v4.ps1 } | iex; R2CloudWindowsServerAgentInstall -token "${this.token.value}" -api "${this.domainPathService.domainPath.apiDomain}" -mon "${this.domainPathService.domainPath.monitoringDomain}" -static "${this.domainPathService.domainPath.staticDomain}" -sec ${this.domainPathService.secureInt}`;
            }
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onCopyScript(value: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.copied = 'Copiado';
        setTimeout(() => {
            this.dialogRef.close();
        }, 1000);
    }
}

