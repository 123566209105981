import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../auth.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataShareService} from '../../data-share.service';
import {fuseAnimations} from '../../../../@fuse/animations';
import {FuseConfigService} from '../../../../@fuse/services/config.service';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {CookieService} from 'ngx-cookie-service';
import {ManagerUserLoginToken, ManagerUserProvider} from '../../models';
import {filter, switchMap, take, takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject} from 'rxjs';
import {UserAuthorityDaoService} from '../user-authority/user-authority-dao.service';
import {MatDialog} from '@angular/material/dialog';
import {BackgroundImageTemplate, CustomerGroupTemplate, CustomPortalTemplate} from '../../models-custom-layout';
import {CustomerGroupTemplateDaoService} from '../custom-layout/customer-group-template/customer-group-template-dao.service';
import {ImageFileService} from '../image-file/image-file-service';
import {CustomerGroupDaoPublicService} from '../customer-group/customer-group-dao-public.service';
import {TwoFactorAuthDaoService} from './two-factor-auth-dao.service';
import {FirebaseService} from '../../firebase.service';

export const customLayoutUrlCookieName = 'customLayoutUrl';

@Component({
    selector: 'fuse-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: fuseAnimations
})
export class FuseLoginComponent implements OnInit, OnDestroy {

    loginForm: FormGroup;
    loginFormErrors: any;
    uri: string;
    credentialsError = false;
    showTokenForm = false;
    loginTransition: string;
    credentialsErrorMessage: string;
    customPortal: any;
    displayOriginalPortal = false;
    customLayoutUrl: string;
    countErrorsLogin = 0;
    countErrorsLoginToken = 0;
    customPortalTemplate: CustomPortalTemplate;
    backgroundImageTemplate: BackgroundImageTemplate;
    customerGroupTemplate: CustomerGroupTemplate;
    loadingCustomLayout = true;
    tokenErrorMsg = '';
    sendingToken = false;
    customerGroupImageUUID: string = null;
    private _unsubscribeAll: Subject<any> = new Subject();
    private loginType$ = new ReplaySubject<ManagerUserProvider>();
    tokenControl: FormControl = new FormControl('');
    minimumTokenSendTimeIntervalMS = 5000;
    canGenerateToken = true;


    capsLockActive = false;

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        this.capsLockActive = event.getModifierState('CapsLock');
    }

    constructor(private fuseConfig: FuseConfigService,
                private formBuilder: FormBuilder,
                private router: Router,
                private titleService: Title,
                private dataShare: DataShareService,
                private twoFactorAuthDaoService: TwoFactorAuthDaoService,
                private firebaseService: FirebaseService,
                private auth: AuthService,
                private userAuthorityDao: UserAuthorityDaoService,
                public imageFileService: ImageFileService,
                private customerGroupTemplateDaoService: CustomerGroupTemplateDaoService,
                private customerGroupDaoPublicService: CustomerGroupDaoPublicService,
                private activatedRoute: ActivatedRoute,
                private sanitizer: DomSanitizer,
                private cookieService: CookieService,
                public dialog: MatDialog
    ) {
        this.loginFormErrors = {
            email: {},
            password: {}
        };


        this.activatedRoute.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe((params: Params) => {
            const userUrl = params['url'];
            this.customLayoutUrl = this.cookieService.get(customLayoutUrlCookieName);
            if (userUrl) {
                this.setCustomLayout(userUrl);
            } else if (this.customLayoutUrl) {
                this.setCustomLayout(this.customLayoutUrl);
            } else {
                this.setCustomLayout('');
                // this.displayOriginalPortal = true;
            }
        });

        this.auth.isLoggedIn().pipe(takeUntil(this._unsubscribeAll), filter(o => o), switchMap(() => {
            return this.loginType$;
        })).subscribe((loginType) => {
            this.onLoginSuccess(loginType);
        });
    }

    setCustomLayout(userUrl: string): void {
        if (userUrl) {
            this.customLayoutUrl = userUrl;
            this.customerGroupDaoPublicService.getImageUUID(userUrl).subscribe((imageUUID) => {
                if (imageUUID) {
                    this.customerGroupImageUUID = imageUUID.value;
                }
            });
        }
        this.customerGroupTemplateDaoService.findOneByCustomerGroupUrl(userUrl).subscribe((customerGroupTemplate) => {
                if (customerGroupTemplate) {
                    this.customerGroupTemplate = customerGroupTemplate;
                    this.cookieService.set(customLayoutUrlCookieName, this.customerGroupTemplate?.url);
                    if (this.customerGroupTemplate?.customPortalTemplate?.title) {
                        this.titleService.setTitle(this.customerGroupTemplate?.customPortalTemplate.title);
                    }
                } else {
                    this.displayOriginalPortal = true;
                }
                this.loadingCustomLayout = false;
            }, () => {
                this.loadingCustomLayout = false;
                this.displayOriginalPortal = true;
            }
        );
    }

    ngOnDestroy(): void {
        let email;
        const emailControl = this.loginForm.get('email');
        if (emailControl.valid) {
            email = emailControl.value;
            this.dataShare.setForgotPasswordEmail(email);
        }
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            email: [this.dataShare.getForgotPasswordEmail(), [Validators.required, Validators.email]],
            password: ['', Validators.required],
            rememberMe: ['']
        });

        this.loginForm.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.onLoginFormValuesChanged();
        });

    }

    onLoginFormValuesChanged(): void {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }

    onLoginWithGoogle(): void {
        this.auth.loginWithGoogle().pipe(take(1)).subscribe((value) => {
            if (value) {
                this.loginType$.next(ManagerUserProvider.GOOGLE);
                // this.onLoginSuccess(ManagerUserProvider.GOOGLE);
            } else {
                this.onCredentialsError(ManagerUserProvider.GOOGLE);
            }
        }, () => {
            this.onCredentialsError(ManagerUserProvider.GOOGLE);
        });
    }

    private onLoginSuccess(loginType: ManagerUserProvider): void {
        console.debug('login onLoginSuccess 1');
        this.userAuthorityDao.getMe().pipe(take(1)).subscribe((me) => {
            if (me) {
                this.countErrorsLogin = 0;
                this.loginTransition = 'login-transition';
                setTimeout(() => {
                    this.auth.returnToOriginalUrl();
                }, 500);
            } else {
                this.onCredentialsError(loginType);
            }
        });
    }


    onLoginWithCredentials(showTokenForm: boolean = false): void {
        this.showTokenForm = showTokenForm;
        if (this.loginForm.valid) {
            const email = this.loginForm.get('email').value;
            const password = this.loginForm.get('password').value;
            const rememberMe = this.loginForm.get('rememberMe').value;
            this.cookieService.set('email', email);
            this.auth.loginWithCredentials(email, password, rememberMe).pipe(take(1)).subscribe(
                (value) => {
                    if (value) {
                        this.loginType$.next(ManagerUserProvider.EMAIL_PASSWORD);
                    } else {
                        this.onCredentialsError(ManagerUserProvider.EMAIL_PASSWORD);
                    }
                    this.sendingToken = false;
                },
                () => {
                    this.onCredentialsError(ManagerUserProvider.EMAIL_PASSWORD);
                    this.sendingToken = false;
                }
            );
        }
    }

    onCredentialsError(loginType: ManagerUserProvider, userIsDisable: boolean = false): void {
        if (loginType === ManagerUserProvider.GOOGLE) {
            this.credentialsErrorMessage = 'Usuário inválido';
        } else if (loginType === ManagerUserProvider.EMAIL_PASSWORD) {
            this.credentialsErrorMessage = 'Usuário ou senha inválidos';
        }
        if (userIsDisable) {
            this.credentialsErrorMessage = 'Usuário desativado, por favor contate o seu administrador';
        }
        if (this.auth.canAccess && !this.auth.canAccess.allowed) {
            this.credentialsErrorMessage = 'Acesso temporariamente bloqueado.';
        }
        this.credentialsError = true;
    }

    onForgotPassword(): void {
        console.log('customLayoutUrl: ', this.customLayoutUrl);
        if (this.customLayoutUrl) {
            this.router.navigate(['forgot-password'], {queryParams: {url: this.customLayoutUrl}});
        } else {
            this.router.navigate(['forgot-password']);
        }
    }


    checkTwoFactor(): void {
        const email = this.loginForm.get('email').value;
        const password = this.loginForm.get('password').value;
        const rememberMe = this.loginForm.get('rememberMe').value;
        this.auth.loginWithCredentialsV2(email, password, rememberMe).pipe(take(1)).subscribe((firebaseToken) => {
                if (firebaseToken) {
                    if (this.canGenerateToken) {
                        this.canGenerateToken = false;
                        this.twoFactorAuthDaoService.generateToken(email).subscribe((value) => {
                            if (value && value.value.toLowerCase() === 'true') {
                                this.showTokenForm = true;
                            } else {
                                this.onLoginWithCredentials();
                            }
                        });
                        this.restartLoginTokenGeneration(this.minimumTokenSendTimeIntervalMS);
                    }
                } else {
                    this.onCredentialsError(ManagerUserProvider.EMAIL_PASSWORD);
                }
            },
            (error) => {
                if (error?.error?.error === 'user is disabled') {
                    this.onCredentialsError(ManagerUserProvider.EMAIL_PASSWORD, true);
                } else {
                    this.onCredentialsError(ManagerUserProvider.EMAIL_PASSWORD);
                }
            });
    }

    private restartLoginTokenGeneration(timer: number): void {
        setTimeout(() => {
            this.canGenerateToken = true;
        }, timer);
    }

    checkLoginToken(): void {
        this.tokenErrorMsg = '';
        this.sendingToken = true;
        let tokenValue = this.tokenControl.value;
        if (isNaN(tokenValue) || !tokenValue) {
            tokenValue = 0;
        }
        const managerUserLoginToken: ManagerUserLoginToken = {
            token: +tokenValue,
            email: this.loginForm.get('email').value
        };
        this.countErrorsLoginToken++;
        if (this.countErrorsLoginToken > 6) {
            window.location.href = '/login';
        }
        this.twoFactorAuthDaoService.checkToken(managerUserLoginToken).subscribe((value) => {
            this.tokenControl.setValue('');
            // console.log('value.toLowerCase()', value.value.toLowerCase());
            if (value && value.value.toLowerCase() === 'true') {
                this.onLoginWithCredentials(true);
            } else {
                this.tokenErrorMsg = 'Token ou e-mail inválidos';
                this.sendingToken = false;
            }
        });
    }
}


