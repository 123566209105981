import {Component, Inject} from '@angular/core';
import {DesktopServerDaoService} from '../desktop-server-dao.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as download from 'download.js';

import {ClientDownloadService} from '../../desktop/client-download.service';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ComponentCleaner} from '../../../component-cleaner';
import {SubProjectDaoService} from '../../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper} from '../../../helpers/kluh';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ADDomain, DesktopServer, DesktopServerPrivateKey, DotNetServerPluginTemplate, FirewallFQDN, Project, SubProject} from '../../../models';
import {EntityHistoryComponent} from '../../javers/entity-history/entity-history.component';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {DotNetServerPluginService} from '../../server-plugin/services/server-plugin.service';
import {DotNetServerAgentFileDaoService} from '../../server-plugin/services/server-agent-file-dao.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {FirewallFqdnComponent} from '../../firewall-fqdn/firewall-fqdn.component';
import {FirewallFqdnDaoService} from '../../firewall-fqdn/firewall-fqdn-dao.service';


@Component({
    selector: 'app-desktop-server-create',
    templateUrl: './desktop-server-create.component.html',
    styleUrls: ['./desktop-server-create.component.scss']
})
export class DesktopServerCreateComponent extends ComponentCleaner {
    myForm: FormGroup;
    adDomains: ADDomain[];
    desktopServer: DesktopServer;
    firewallFQDNList: FirewallFQDN[] = [];
    desktopServers: DesktopServer[];
    subProject: SubProject;
    project: Project;
    serverPluginTemplates$: Observable<DotNetServerPluginTemplate[]>;

    // copyInAllSharedSubProjectsFilterControl: FormControl = new FormControl();

    constructor(public dialogRef: MatDialogRef<DesktopServerCreateComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private desktopServerDaoService: DesktopServerDaoService,
                private subProjectDaoService: SubProjectDaoService,
                private fb: FormBuilder,
                private adminService: R2CloudAdminService,
                private dialog: MatDialog,
                public clientDownloadService: ClientDownloadService,
                private firewallFqdnDaoService: FirewallFqdnDaoService,
                public serverPluginService: DotNetServerPluginService,
                public serverAgentFileDao: DotNetServerAgentFileDaoService,
                public domainPathService: DomainPathService,
    ) {
        super();
        const newDesktopServer: DesktopServer = {
            disableUpdate: false,
            adDomainId: null,
            clientId: '',
            publicIp: '',
            privateIp: '',
            hostname: '',
            connectionFQDN: '',
            firewallFQDNIds: [],
            connectionPort: null,
            id: null,
            comment: '',
            active: true,
            remoteAppIds: [],
            gatewayFQDN: null,
            serverPluginTemplateIds: [],
            modified: null,
            optlock: null
        };
        this.adDomains = data.adDomains;
        this.subProject = data.subProject;
        this.desktopServers = data.desktopServers;
        this.project = data.project;
        this.desktopServer = data.desktopServer;
        if (this.adDomains) {
            this.adDomains = this.adDomains.filter(x => x.active === true);
        }
        // tendo um só dominio já deixa selecionado
        if (this.adDomains && this.adDomains.length === 1) {
            newDesktopServer.adDomainId = this.adDomains[0].id;
            if (this.desktopServer) {
                this.desktopServer.adDomainId = this.adDomains[0].id;
            }
        }

        if (this.desktopServer) {
            // ativando o validator o hostname fica sempre invalido
            this.myForm = this.fb.group(convertToFormGroup(this.desktopServer), {asyncValidator: this.desktopServerDaoService.validator});
            // this.myForm = this.fb.group(this.desktopServer, {});
        } else {
            this.myForm = this.fb.group(convertToFormGroup(newDesktopServer), {asyncValidator: this.desktopServerDaoService.validator});
        }

        this.firewallFqdnDaoService.findAllByDesktopServerId(this.desktopServer.id).subscribe((firewallFQDNList) => {
            if (firewallFQDNList) {
                this.firewallFQDNList = firewallFQDNList;
            }
        });

    }

    onSubmitConfirm(): void {
        let desktopServer$: Observable<DesktopServer>;
        let operation: CrudOperation;
        let desktopServerPrivateKey: string;
        const crudOperations: CrudOperationWrapper[] = [];
        const desktopServer: DesktopServer = this.myForm.value;

        const copyInAllSharedSubProjectsFilterControl = false;

        if (desktopServer.id) {
            operation = 'SAVE';
            desktopServer$ = this.desktopServerDaoService.save(desktopServer);
        } else {
            operation = 'CREATE';
            desktopServer$ = this.desktopServerDaoService.createDesktopServerInAllSubProjectsOfProjectAndGetPrivateKey(
                desktopServer, this.subProject.id, copyInAllSharedSubProjectsFilterControl)
                .pipe(switchMap(
                    (desktopServerWithPrivateKey: DesktopServerPrivateKey) => {
                        if (desktopServerWithPrivateKey) {
                            desktopServerPrivateKey = desktopServerWithPrivateKey.privateKey;
                            const desktopServerObservable = this.desktopServerDaoService.getOne(desktopServerWithPrivateKey.serverId);
                            this.subProject.desktopServerIds.push(desktopServerWithPrivateKey.serverId);
                            return desktopServerObservable;
                        } else {
                            return of(null);
                        }
                    }));
        }
        desktopServer$.subscribe((result: DesktopServer) => {
            if (desktopServerPrivateKey) {
                download.downloadText(result.hostname + '-' + result.clientId + '.txt', desktopServerPrivateKey);
            }
            crudOperations.push({
                data: result,
                operation: operation
            });
            if (!this.subProject.desktopServerIds) {
                this.subProject.desktopServerIds = [];
            }
            let subProject$: Observable<SubProject>;
            let subProjectOperation: CrudOperation;
            const index = this.subProject.desktopServerIds.findIndex(value => {
                return value === result.id;
            });
            if (index === -1) {
                this.subProject.desktopServerIds.push(result.id);
                subProjectOperation = 'SAVE';
                subProject$ = this.subProjectDaoService.save(this.subProject);
            } else {
                subProjectOperation = 'CANCEL';
                subProject$ = of(this.subProject);
            }
            subProject$.subscribe((result2) => {
                crudOperations.push({
                    data: result2,
                    operation: subProjectOperation
                });
                this.dialogRef.close(crudOperations);
            });
        });

    }

    onSubmit(): void {
        if (!this.myForm.value.id) {
            const subscription = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Assim que salvar as configurações desse servidor, <br />' +
                        'seu navegador fará o <u>download de um arquivo .txt</u> ' +
                        'contendo a chave criptografada <br /> ' +
                        'que será usada para instalar o Client nesse mesmo servidor.',
                    disableCancel: true,
                    confirmButtonValue: 'OK',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe(() => {
                this.onSubmitConfirm();
            });
            this.addSubscription(subscription);
        } else {
            this.onSubmitConfirm();
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onReallyDelete(): void {
        const desktopServer: DesktopServer = this.myForm.value;
        this.desktopServerDaoService.findAllSubProjectIds(desktopServer.id).subscribe(subProjectIds => {
            if (subProjectIds && subProjectIds.length === 1) {
                const crudOperations: CrudOperationWrapper[] = [];
                this.addSubscription(this.dialog.open(ConfirmDialogComponent, {
                    disableClose: true,
                    data: {
                        message: '<b>Confirme</b> a exclusão digitando o nome do servidor: <b>' + desktopServer.hostname +
                            '</b> no campo abaixo <br> Esse procedimento não tem volta e deletará tudo que for relacionado a esse servidor.',
                        disableCancel: false,
                        confirmButtonValue: 'OK',
                        icon: 'error_outline',
                        confirmFieldValue: desktopServer.hostname
                    }
                }).afterClosed().subscribe((result) => {
                    if (result) {
                        this.desktopServerDaoService.remove(desktopServer.id).subscribe(() => {
                            const index = this.subProject.desktopServerIds.findIndex(value => {
                                return value === desktopServer.id;
                            });
                            crudOperations.push({
                                data: desktopServer,
                                operation: 'DELETE'
                            });
                            this.adminService.removeRemoteAppByDesktopServerId(desktopServer.id);
                            if (index !== -1) {
                                let subProject$: Observable<SubProject>;
                                this.subProject.desktopServerIds.splice(index, 1);
                                subProject$ = of(this.subProject);
                                subProject$.subscribe((result3) => {
                                    crudOperations.push({
                                        data: result3,
                                        operation: 'DELETE'
                                    });
                                    this.dialogRef.close(crudOperations);
                                });
                            } else {
                                this.dialogRef.close();
                            }

                        });
                    }
                }));
            } else {
                this.dialog.open(ConfirmDialogComponent, {
                    disableClose: true,
                    data: {
                        message: 'Não é permitido deletar um servidor compartilhado antes que o mesmo<br>seja removido dos outros projetos.<br><br>' +
                            'Servidor usado em:<br> <b>' + subProjectIds.length + '</b> projetos',
                        disableCancel: true,
                        confirmButtonValue: 'OK',
                        icon: 'error_outline'
                    }
                });
            }
        });

    }

    onDelete(): void {
        const crudOperations: CrudOperationWrapper[] = [];
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover o servidor desse projeto?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const desktopServer: DesktopServer = this.myForm.value;
                this.desktopServerDaoService.deleteDesktopServerFromSubProject(desktopServer.id, this.subProject.id).subscribe(() => {
                    const index = this.subProject.desktopServerIds.findIndex(value => {
                        return value === desktopServer.id;
                    });
                    crudOperations.push({
                        data: desktopServer,
                        operation: 'DELETE'
                    });
                    if (index !== -1) {
                        let subProject$: Observable<SubProject>;
                        this.subProject.desktopServerIds.splice(index, 1);
                        subProject$ = of(this.subProject);
                        subProject$.subscribe((result3) => {
                            crudOperations.push({
                                data: result3,
                                operation: 'DELETE'
                            });
                            this.dialogRef.close(crudOperations);
                        });
                    } else {
                        this.dialogRef.close();
                    }

                });
            }
        });
        this.addSubscription(subscription);
    }

    onJaversHistory(): void {
        EntityHistoryComponent.openHistory(this.desktopServer.id, this.desktopServerDaoService, this.dialog);
    }

    onSyncServer(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Ao sincronizar o servidor, será enviado comandos para recriar todos os ' +
                    'Aplicativos do servidor, <br />caso seja controlador de domínio será enviado também para Grupos e Usuários.<br /><br />' +
                    '<b>Deseja continuar?</b>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const desktopServer: DesktopServer = this.myForm.value;
                this.desktopServerDaoService.syncServer(desktopServer.id).subscribe((result2) => {
                    if (result2) {
                        this.dialogRef.close();
                    }
                });
            }
        });
        this.addSubscription(subscription);
    }

    onGenerateNewPrivateKey(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Ao gerar uma nova chave privada, será necessário reinstalar o agente no servidor.<br />' +
                    'Deseja continuar?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const desktopServer: DesktopServer = this.myForm.value;
                this.desktopServerDaoService.generateNewPrivateKey(desktopServer.id).subscribe((result2) => {
                    if (result2) {
                        download.downloadText(desktopServer.hostname + '-' + desktopServer.clientId + '.txt',
                            result2.privateKey);
                        this.dialogRef.close();
                    } else {
                        //  TODO Erro
                    }
                });
            }
        });
        this.addSubscription(subscription);
    }

    onAgentUpdate(desktopServerId: number): void {

        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja atualizar o agent do Windows Server desse servidor?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.serverAgentFileDao.sendUpdate(desktopServerId).subscribe(() => {
                    this.dialogRef.close();
                });
            }
        });
    }

    onGetLocalAdmin(desktopServerId: number): void {
        this.desktopServerDaoService.getLocalAdmin(desktopServerId).subscribe((localAdmin) => {
            if (localAdmin) {
                this.dialog.open(ConfirmDialogComponent, {
                    disableClose: true,
                    data: {
                        message: '<h2>Credenciais do servidor:</h2><br>' + localAdmin.localAdmin + '<br>' + localAdmin.localAdminPassword,
                        disableCancel: true,
                        confirmButtonValue: 'OK',
                        icon: 'error_outline'
                    }
                }).afterClosed().subscribe(() => {
                });
            }
        });
    }

    openFirewallFQDNList(): void {
        this.dialog.open(FirewallFqdnComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                desktopServer: this.desktopServer,
                firewallFQDNList: this.firewallFQDNList
            }
        }).afterClosed().subscribe((result: FirewallFQDN[]) => {
            if (result) {
                this.firewallFQDNList = result;
            }
        });
    }
}
