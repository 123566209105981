<div class="page-layout carded fullwidth">
    <div class="accent"></div>
    <div class="header accent header-medium" fxLayout="row" fxLayoutAlign="start center">
        <h1>Lista de empresas</h1>
    </div>
    <div class="content p-24">
        <div fxLayout="column">
            <div class="row">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                            <div>Dashboard</div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="padding-none">
                        <div>
                            <div *appIsAdmin class="full-width" fxLayout="row" style="padding: 20px 20px 0 20px;"
                                 fxLayoutAlign="start center">
                                <div>
                                    <mat-form-field class="full-width min-width-450" style="max-width: 450px;" appearance="outline">
                                        <mat-label>Selecionar parceiro</mat-label>
                                        <mat-select class="full-width" placeholder="Selecionar parceiro"
                                                    [formControl]="partnerCustomerGroupControl" multiple>
                                            <mat-option [value]="0" #allSelected (click)="toggleAllSelection()" >Todos</mat-option>
                                            <mat-option [value]="-2" (click)="tosslePerOne()">Sem parceiro</mat-option>
                                            <mat-option
                                                *ngFor="let partnerCustomerGroup of partnerCustomerGroups; let i = index;"
                                                [value]="partnerCustomerGroup.customerGroupId" (click)="tosslePerOne()">
                                                {{partnerCustomerGroup.customerGroupName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <div style="margin-bottom: 22px;">
                                            <mat-checkbox class="mt-24" style="margin-left: 30px;margin-right: 10px;"
                                                          [formControl]="getManagerUserCreatedCountControl">
                                                Pegar os usuários criados em:
                                            </mat-checkbox>
                                        </div>
                                        <div>
                                            <mat-form-field  appearance="outline">
                                                <mat-label>Dias</mat-label>
                                                <mat-select class="full-width" placeholder="Dias" [formControl]="daysCreatedControl">
                                                    <mat-option value="{{i+1}}" *ngFor="let item of [].constructor(93); let i = index">{{i+1}} dias</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-card class="dash-card">
                                <div
                                    class="font-size-54 font-weight-300 line-height-1 mt-8 ng-tns-c206-146">{{amountOfManagerUsers}}</div>
                                <div class="h5 secondary-text ng-tns-c206-146">Total de usuários
                                    <div><b style="text-transform: uppercase">cadastrados</b></div>
                                </div>
                            </mat-card>

                            <mat-card class="dash-card">
                                <div
                                    class="font-size-54 font-weight-300 line-height-1 mt-8 ng-tns-c206-146">{{amountOfContractedUsers}}</div>
                                <div class="h5 secondary-text ng-tns-c206-146">Total de usuários
                                    <div><b style="text-transform: uppercase">simultâneos</b></div>
                                </div>
                            </mat-card>

                            <mat-card class="dash-card">
                                <div
                                    class="font-size-54 font-weight-300 line-height-1 mt-8 ng-tns-c206-146">{{dataSource?.data?.length}}</div>
                                <div class="h5 secondary-text ng-tns-c206-146">Total de
                                    <div><b style="text-transform: uppercase">empresas</b></div>
                                </div>
                            </mat-card>

                            <mat-card class="dash-card">
                                <div
                                    class="font-size-54 font-weight-300 line-height-1 mt-8 ng-tns-c206-146">{{amountOfADUsers}}</div>
                                <div class="h5 secondary-text ng-tns-c206-146">Total de
                                    <div><b style="text-transform: uppercase">Usuários Comuns</b></div>
                                </div>
                            </mat-card>

                            <mat-card class="dash-card">
                                <div
                                    class="font-size-54 font-weight-300 line-height-1 mt-8 ng-tns-c206-146">{{amountOfADUsersPool}}</div>
                                <div class="h5 secondary-text ng-tns-c206-146">Total de relações
                                    <div><b style="text-transform: uppercase">Usuários Pool</b></div>
                                </div>
                            </mat-card>
                        </div>
                        <div style="clear: both">

                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mb-20 mt-30 width-100-percent">
                    <mat-card-header>
                        <mat-card-title>Empresas</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>

                        <table mat-table [dataSource]="dataSource" matSortActive="differenceBetweenContractedUsersAndCreatedUsers"
                               matSortDirection="asc" class="mat-elevation-z8 rows-pointer" matSort>
                            <!-- Position Column -->
                            <ng-container matColumnDef="image">
                                <th mat-header-cell *matHeaderCellDef style="width: 70px;" class="header"></th>
                                <td mat-cell *matCellDef="let element" class="p-10">
                                    <a [routerLink]="['/register']" [queryParams]="{id: element.customerGroupId }">
                                        <img alt="{{element.customerGroupName}}"
                                            src="{{imageFileService.getCustomerGroupPictureUrl(element.customerGroupImageUUID, 34)}}"
                                            width="34"
                                            height="34"></a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="customerGroupName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"> Name</th>
                                <td mat-cell *matCellDef="let element" class="p-10">
                                    <a [routerLink]="['/register']" class="blue-color"
                                       [queryParams]="{id: element.customerGroupId }"> {{element.customerGroupName}}</a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="customerGroupCreatedAt">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"> Criado</th>
                                <td mat-cell *matCellDef="let element" class="p-10">
                                    {{element.customerGroupCreatedAt  | date: 'dd/MM/yyyy HH:mm'}}
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="amountOfManagerUsers">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"> Usuários
                                    cadastrados
                                </th>
                                <td mat-cell *matCellDef="let element" class="nuns p-10">
                                    {{element.amountOfManagerUsers}} </td>
                            </ng-container>

                            <ng-container matColumnDef="amountOfContractedUsers">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"> Usuários
                                    simultâneos
                                </th>
                                <td mat-cell *matCellDef="let element" class="nuns p-10">
                                    {{element.amountOfContractedUsers}} </td>
                            </ng-container>

                            <ng-container matColumnDef="differenceBetweenContractedUsersAndCreatedUsers">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"> Diferença
                                </th>
                                <td mat-cell *matCellDef="let element" class="nuns p-10">
                                    <span
                                        class="users-minus-contracted-users">{{element.differenceBetweenContractedUsersAndCreatedUsers}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="amountOfCustomerGroupsExternalADUsers">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"> Usuários externos
                                </th>
                                <td mat-cell *matCellDef="let element" class="nuns p-10">
                                    <span
                                        class="users-minus-contracted-users">{{element.amountOfCustomerGroupsExternalADUsers}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="amountOfCustomerGroupsADUsers">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"> Usuários comuns
                                </th>
                                <td mat-cell *matCellDef="let element" class="nuns p-10">
                                    <span
                                        class="users-minus-contracted-users">{{element.amountOfCustomerGroupsADUsers}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="amountOfCustomerGroupsADUsersPool">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"> Usuários pool
                                </th>
                                <td mat-cell *matCellDef="let element" class="nuns p-10">
                                    <span
                                        class="users-minus-contracted-users">{{element.amountOfCustomerGroupsADUsersPool}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="header">
                                </th>
                                <td mat-cell *matCellDef="let element" class="p-10">
                                    <button (click)="onEditCustomerGroup(element.customerGroupId)" mat-raised-button>
                                        Editar
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>