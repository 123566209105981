import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AsyncValidatorFn} from '@angular/forms';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {BaseSearchDao} from '../../base-search-dao';
import {Project, ProjectSharedInfo} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class ProjectDaoService extends BaseSearchDao<Project> {
    validator: AsyncValidatorFn = this.validatorDAO.validator(this.url, this.http);

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'projects');
    }


    getMyProjects(): Observable<Project[]> {
        return this.http.get<Project[]>('projects/get-my-projects');
    }

    getProjectSharedInfo(projectId: number, subProjectId: number): Observable<ProjectSharedInfo> {
        return this.http.get<ProjectSharedInfo>('projects/project-shared-info/?id=' + projectId + '&subProjectId=' + subProjectId);
    }
}
