import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatusComponent} from './status.component';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatDialogModule} from '@angular/material/dialog';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {KluhSharedModule} from '../../kluh-shared.module';
import {ManagerUserOnlineDetailsComponent} from './manager-user-online-details/manager-user-online-details.component';
import {SendStompMessageComponent} from './manager-user-online-details/send-stomp-message/send-stomp-message.component';
import {ServersOfflineComponent} from './servers-offline/servers-offline.component';
import {ServersOnlineComponent} from './servers-online/servers-online.component';
import {AdUserChangingOrDeletingComponent} from './ad-user-changing-or-deleting/ad-user-changing-or-deleting.component';
import {AdGroupChangingOrDeletingComponent} from './ad-group-changing-or-deleting/ad-group-changing-or-deleting.component';
import {RemoteAppsChangingOrDeletingComponent} from './remote-apps-changing-or-deleting/remote-apps-changing-or-deleting.component';
import {ProjectChangingOrDeletingComponent} from './project-changing-or-deleting/project-changing-or-deleting.component';
import {ManagerUserChangingOrDeletingComponent} from './manager-user-changing-or-deleting/manager-user-changing-or-deleting.component';
import {AdUserSnapshotsComponent} from './ad-user-snapshots/ad-user-snapshots.component';
import {AdGroupSnapshotsComponent} from './ad-group-snapshots/ad-group-snapshots.component';
import {RemoteAppSnapshotsComponent} from './remote-app-snapshots/remote-app-snapshots.component';
import {DesktopServerVersionComponent} from './desktop-server-version/desktop-server-version.component';
import {ManagerUserOnlineComponent} from './manager-user-online/manager-user-online.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AccessChartComponent} from './access-chart/access-chart.component';
import {K8sComponent} from './k8s/k8s.component';
import {StatusDaoService} from './status-dao.service';
import {SessionStatusComponent} from './session-status/session-status.component';


const routes = [
    {
        path: 'status',
        component: StatusComponent,
        canActivate: [AuthService],
        data: {permissions: ['ADUserPool.AUDIT']}
    }
];

@NgModule({
    imports: [

        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatIconModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatCardModule,
        MatButtonModule,
        MatSlideToggleModule,
        FuseWidgetModule,
        KluhSharedModule,
        FuseSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatCardModule,
        MatIconModule,
        MatToolbarModule,
        MatStepperModule,
        MatRadioModule,
        MatSelectModule,
        FontAwesomeModule,
        MatProgressSpinnerModule

    ],
    declarations: [
        StatusComponent,
        ManagerUserOnlineDetailsComponent,
        SendStompMessageComponent,
        ServersOfflineComponent,
        ServersOnlineComponent,
        AdUserChangingOrDeletingComponent,
        AdGroupChangingOrDeletingComponent,
        RemoteAppsChangingOrDeletingComponent,
        ProjectChangingOrDeletingComponent,
        ManagerUserChangingOrDeletingComponent,
        AdUserSnapshotsComponent,
        AdGroupSnapshotsComponent,
        RemoteAppSnapshotsComponent,
        DesktopServerVersionComponent,
        ManagerUserOnlineComponent,
        AccessChartComponent,
        K8sComponent,
        SessionStatusComponent
    ],
    entryComponents: [
        ManagerUserOnlineDetailsComponent,
        SendStompMessageComponent],
    providers: [StatusDaoService]
})
export class StatusModule {
}
