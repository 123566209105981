<form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Usuário de acesso R2 Cloud</span>
        </div>
    </mat-toolbar>
    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch">
        <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch" *ngIf="myForm && !hasNewEmail">
            <table border="0" cellspacing="0">
                <tr>
                    <td valign="top" class="w-400">
                        <ng-container>

                            <ng-container formGroupName="adUserForm">
                                <mat-form-field
                                    [ngClass]="{'hidden': isEditForm || (adDomains && adDomains.length === 1) || (adUser && adUser.id)}">
                                    <mat-select placeholder="Domínio" formControlName="adDomainId">
                                        <mat-option *ngFor="let adDomain of adDomains" [value]="adDomain.id">
                                            {{adDomain.domainName}} | {{adDomain.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div style="margin-bottom: 15px;"
                                     *ngIf="isEditForm && adDomains && adDomains.length > 1">
                                    <div class="font-weight-900">Domínio:</div>
                                    <div>{{adminService.getAdDomain(myForm.get('adUserForm').get('adDomainId').value).domainName}}</div>
                                </div>

                            </ng-container>

                            <ng-container *ngIf="!myForm.get('adUserForm').get('serviceUser').value"
                                          formGroupName="managerUserForm">
                                <mat-form-field [ngClass]="{'hidden': isEditForm}">
                                    <input matInput formControlName="email" placeholder="E-mail">
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('email').errors | error}}
                                    </mat-error>
                                    <mat-error>
                                        {{myForm.get('adUserForm').get('managerUserId').errors}}
                                    </mat-error>
                                </mat-form-field>


                                <div style="margin-bottom: 15px;" *ngIf="isEditForm">
                                    <div class="font-weight-900">E-mail:</div>
                                    <div>{{myForm.get('managerUserForm').get('email').value}}</div>
                                </div>


                            </ng-container>

                            <ng-container formGroupName="adUserForm">


                                <div style="margin-top: 15px;margin-bottom: 15px"
                                     *ngIf="!myForm.get('adUserForm').get('serviceUser').value"
                                     [ngClass]="{'hidden': isEditForm}">
                                    <mat-checkbox aria-label="Cadastrar usuário de RDP existente"
                                                  [formControl]="adUserAlreadyExists">
                                        Cadastrar usuário de RDP existente
                                    </mat-checkbox>

                                    <mat-form-field [ngClass]="{'hidden': adUserAlreadyExists.value === false}">
                                        <input matInput formControlName="login" placeholder="Login AD">
                                        <mat-error>
                                            {{myForm.get('adUserForm').get('login').errors | error}}
                                        </mat-error>
                                    </mat-form-field>

                                </div>

                                <div style="margin-bottom: 25px"
                                     [ngClass]="{'hidden': isEditForm || adUserAlreadyExists.value === false}">
                                    <mat-checkbox aria-label="Pedir senha para o usuário quando logar no R2"
                                                  [formControl]="askThePassword">
                                        Pedir senha para o usuário quando logar no R2
                                    </mat-checkbox>
                                    <div class="mat-form-field-wrapper"
                                         [ngClass]="{'hidden': askThePassword.value === true}">
                                        <div class="mat-form-field-flex">
                                            <div class="mat-form-field-infix">
                                                <input style="border-bottom: solid 1px #ddd; padding-bottom: 5px;"
                                                       matInput
                                                       type="password" placeholder="AD User password"
                                                       [formControl]="adUserPassword">
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div style="margin-bottom: 15px;" *ngIf="isEditForm">
                                    <div class="font-weight-900">Filial:</div>
                                    <div>{{customer?.name}}</div>
                                </div>


                                <div style="margin-bottom: 15px;" *ngIf="isEditForm">
                                    <div class="font-weight-900">Login:</div>
                                    <div>{{myForm.get('adUserForm').get('login').value}}</div>
                                </div>

                                <div *appIfPermission="appCanCreateList" class="mb-30">
                                    <div style="margin-bottom: 15px;" *ngIf="isEditForm">
                                        <div
                                            *ngIf="!currentEditManagerUserIsSuperAdmin || currentEditManagerUserIsTheSameUser">
                                            <div class="font-weight-900">Senha:</div>
                                            <div *ngIf="adUserPw ==''">
                                                <button type="button" mat-raised-button
                                                        (click)="findByLoginPw(myForm.get('adUserForm').get('login').value, myForm.get('adUserForm').get('adDomainId').value)">
                                                    Mostrar senha
                                                </button>
                                            </div>
                                            <div *ngIf="adUserPw !=''">
                                                {{adUserPw}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *appIsAdmin>
                                    <div *ngIf="!adAdmin.id">
                                        <div style="margin-bottom: 15px;" *ngIf="isEditForm">
                                            <div *ngIf="adAdmin.changing" class="red-A700-fg">
                                                <b>Alteração em andamento, aguarde alguns minutos.</b>
                                            </div>
                                            <div *ngIf="adAdmin.deleting" class="red-A700-fg">
                                                <b>Aguardando a remoção do adUser da lista de administradores, aguarde
                                                    alguns minutos. </b>
                                            </div>
                                            <button *ngIf="(!(adAdmin.changing || adAdmin.deleting))" type="button"
                                                    color="accent" mat-raised-button
                                                    (click)="onPutAdUserAsAdmin()">
                                                Colocar como Admin do Domínio
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div *appIsAdmin>
                                    <div *ngIf="adAdmin.id">
                                        <div style="margin-bottom: 15px;" *ngIf="isEditForm">
                                            <div *ngIf="adAdmin.changing" class="red-A700-fg">
                                                <b>Alteração em andamento, aguarde alguns minutos.</b>
                                            </div>
                                            <div *ngIf="adAdmin.deleting" class="red-A700-fg">
                                                <b>Aguardando a remoção do adUser da lista de administradores, aguarde
                                                    alguns minutos.</b>
                                            </div>
                                            <button *ngIf="(!(adAdmin.changing || adAdmin.deleting))" type="button"
                                                    color="warn" mat-raised-button
                                                    (click)="onRemoveAdUserAsAdmin()">
                                                Remover o AdUser como Admin do Domínio
                                            </button>

                                        </div>
                                    </div>
                                </div>

                                <div class="saving-groups">
                                    <div fxLayout="column" fxLayoutAlign="start end">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Grupos</mat-label>
                                            <mat-select placeholder="Grupos" [formControl]="adGroupIds" multiple>
                                                <mat-option *ngFor="let group of adminService.adGroups" [value]="group.id">
                                                    {{group.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <button type="button" mat-raised-button color="accent"
                                                [disabled]="adGroupIds.pristine || adGroupListHasChange()"
                                                (click)="savingGroupsInUser()">
                                            Salvar grupos
                                        </button>
                                    </div>
                                </div>


                                <mat-form-field *ngIf="myForm.get('adUserForm').get('serviceUser').value">
                                    <mat-select placeholder="User" formControlName="managerUserServiceIds" multiple>
                                        <mat-option *ngFor="let managerUser of managerUsers" [value]="managerUser.id">
                                            {{managerUser.email}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>


                            </ng-container>

                        </ng-container>
                    </td>
                    <td valign="top">
                        <div *appIfPermission="appCanCreateList">
                            <div>
                                <mat-form-field class="width-100-percent">
                                    <mat-select placeholder="Selecione o Servidor"
                                                [formControl]="desktopServerFilterControl">
                                        <mat-option
                                            *ngFor="let desktopServer of getDesktopServerListByADDomainId(adUser.adDomainId)"
                                            [value]="desktopServer">
                                            {{desktopServer.hostname}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutAlign="start end">
                                <div class="mt-5">
                                    <button [disabled]="!desktopServerFilterControl?.value?.id" type="button"
                                            mat-raised-button color="primary"
                                            (click)="onUserLogOff(myForm.get('adUserForm').get('login').value)">
                                        Desconectar
                                    </button>
                                </div>
                                <div class="mt-5">
                                    <button [disabled]="!desktopServerFilterControl?.value?.id" type="button"
                                            mat-raised-button color="primary"
                                            (click)="onUserReset(myForm.get('adUserForm').get('login').value)">Forçar
                                        Desconexão
                                    </button>
                                </div>
                                <div class="mt-5">
                                    <button [disabled]="!desktopServerFilterControl?.value?.id" type="button"
                                            mat-raised-button color="accent"
                                            (click)="openDesktopServerCustomUser()">
                                        Abrir como esse usuário
                                    </button>
                                </div>

                            </div>

                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div *ngIf="alertError != ''" class="alertError warn-900-fg">{{alertError}}</div>


        <div *ngIf="hasNewEmail" class="new-user">
            <div color="warn"> O e-mail <b> {{myForm.get('managerUserForm').get('email').value}} </b> não está
                cadastrado no R2 Cloud, <br/> <b>deseja cadastrar?</b></div>


            <!--<mat-form-field *ngIf="allAuthorities && allAuthorities.length > 0" formGroupName="managerUserForm">-->
            <!--<mat-select placeholder="Autorizações" formControlName="authorityIds" multiple>-->
            <!--<mat-option *ngFor="let authority of allAuthorities" [value]="authority.id">-->
            <!--{{authority.authority.replace("ROLE_", "")}}-->
            <!--</mat-option>-->
            <!--</mat-select>-->
            <!--</mat-form-field>-->


            <div class="container" style="margin-top: 15px;">
                <div class="row">
                    <div class="col-sm buttons-register">
                        <button mat-raised-button color="accent" class="md-small" (click)="onRegisterNewUser()"
                                [disabled]="!allAuthorities">Sim
                        </button>
                    </div>
                    <div class="col-sm  buttons-register">
                        <button mat-raised-button color="primary" class="md-small" (click)="onCloseNewUser()">Não
                        </button>
                    </div>
                </div>
            </div>

        </div>


        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
             *ngIf="!hasNewEmail">
            <div matTooltip="Salvar" *ngIf="!isEditForm || (myForm.get('adUserForm').get('serviceUser').value) ">

                <button type="submit" mat-raised-button color="accent" [disabled]="myForm.pristine || !myForm.valid"
                        aria-label="Salvar">
                    <span *ngIf="isEditForm;else newUser">Salvar</span>
                    <ng-template #newUser>Criar</ng-template>
                </button>

            </div>

            <div>
                <button mat-stroked-button [matMenuTriggerFor]="menu" class="small-button">
                    <mat-icon>more_vert</mat-icon>
                    <span>Menu</span>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item
                            *ngIf="isEditForm"
                            (click)="onRemove()">
                        <mat-icon>remove_circle</mat-icon>
                        <span>Excluir</span>
                    </button>
                    <button mat-menu-item
                            *ngIf="!currentEditManagerUserIsSuperAdmin || currentEditManagerUserIsTheSameUser"
                            (click)="onResetPassword()">
                        <mat-icon>lock_reset</mat-icon>
                        <span>Resetar senha</span></button>
                    <button mat-menu-item
                            *ngIf="!currentEditManagerUserIsSuperAdmin || currentEditManagerUserIsTheSameUser"
                            (click)="onSetRandomPassword()">
                        <mat-icon>refresh</mat-icon>
                        <span>Gerar senha aleatória</span>
                    </button>
                    <button mat-menu-item
                            *ngIf="!currentEditManagerUserIsSuperAdmin || currentEditManagerUserIsTheSameUser"
                            (click)="onSetLegacyRandomPassword()">
                        <mat-icon>refresh</mat-icon>
                        <span>Gerar senha aleatória (Legada)</span>
                    </button>
                </mat-menu>
            </div>
            <div>
                <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
            </div>
        </div>
    </div>
</form>
