import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {CustomPortalTemplateDaoService} from './custom-portal-list/custom-portal-template-dao.service';
import {BackgroundImageTemplate, CustomPortalTemplate} from '../../models-custom-layout';
import {BackgroundImageTemplateDaoService} from './background-image-template-list/background-image-template-dao.service';
import {Observable} from 'rxjs';
import {DomainPathService} from '../../domain-path/domain-path.service';

@Injectable({
    providedIn: 'root'
})
export class CustomLayoutService {
    private customPortalTemplateFromURL: CustomPortalTemplate = null;
    private customPortalTemplateFromManagerUser: CustomPortalTemplate = null;
    private backgroundImageTemplateFromURL: BackgroundImageTemplate = null;
    private backgroundImageTemplateFromManagerUser: BackgroundImageTemplate = null;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private customPortalTemplateDaoService: CustomPortalTemplateDaoService,
        private backgroundImageTemplateDaoService: BackgroundImageTemplateDaoService,
        private domainPathService: DomainPathService,
    ) {

    }

    getBackgroundImageTemplateByUrl(customPortalUrl: string): Observable<BackgroundImageTemplate> {
        return this.backgroundImageTemplateDaoService.findOneByCustomerGroupUrl(customPortalUrl);
    }

    getCustomPortalTemplateByManagerUserId(managerUserId: number): CustomPortalTemplate {
        if (!this.customPortalTemplateFromManagerUser) {
            // aqui busca no projeto CustomLayout o CustomLayout do managerUser logado
            if (managerUserId) {
                // this.getCustomPortalTemplateByUrl('asdasd');
                // this.customPortalTemplateDaoService.findByManagerUserId(managerUserId).subscribe((customPortalTemplate) => {
                //     if (customPortalTemplate) {
                //         return customPortalTemplate;
                //     }
                // });

            }
        }
        return this.customPortalTemplateFromURL;
    }


    getCustomPortalTemplateByUrl(customPortalUrl: string): CustomPortalTemplate {
        if (!this.customPortalTemplateFromURL) {
            // busca no projeto customLayout o template baseado pela url
            this.customPortalTemplateFromURL = {
                id: null,
                createdAt: null,
                updatedAt: null,
                creatorCustomerGroupId: null,
                public: null,
                name: 'teste2',
                title: null,
                hasCustomProtocol: null,
                customProtocol: null,
                installUrl: null,
                customProtocolSoftwareName: null,
                helpLink: null,
                logoId: 1,
                css: ''
            };
        }
        return this.customPortalTemplateFromURL;
    }

    setDynamicStyle(css: string): void {
        if (css) {
            const head = this.document.getElementsByTagName('head')[0];
            const style = this.document.createElement('style');
            style.setAttribute('id', 'custom-layout');
            style.appendChild(document.createTextNode(css));
            head.appendChild(style);
        }
    }

    removeAllCustomStyle(): void {
        const elementCustomLayout = this.document.getElementById('custom-layout');
        if (elementCustomLayout) {
            elementCustomLayout.remove();
        }
    }

    changeTemplate(customPortalTemplate: CustomPortalTemplate): void {
        this.changeLogo(customPortalTemplate?.logoId);
        this.setDynamicStyle(customPortalTemplate?.css);
        // this.changeHomeHTML(customPortalTemplate?.html);
    }

    changeLogo(logoUrl: number): void {
        if (logoUrl) {
            const logo = this.document.getElementById('top-logo');
            if (logo) {
                console.log('customLayout: tem logo');
            }
        }
    }

    changeHomeHTML(homeHtml: string): void {
        if (homeHtml) {
            const home = this.document.getElementById('login-intro');
            if (home) {
                home.innerHTML = homeHtml;
                console.log('customLayout: homeHtml');
            }
        }
    }
}
