<ng-container *ngIf="customerGroup?.id">
    <mat-card>
        <mat-card-header>
            <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                <div> Projetos</div>
                <div>
                    <button
                        *appIfPermission="appCanCreateList"
                        type="button" mat-raised-button color="accent"
                        (click)="onCreateSubProject()" id="r2-cloud-admin-sub-project-create-project">Criar
                    </button>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div *ngIf="subProjects && subProjects.length > 1">
                <mat-form-field class="projects" appearance="outline">
                    <mat-label>Projeto</mat-label>
                    <mat-select placeholder="Projetos" [formControl]="subProjectFilterControl">
                        <mat-option *ngFor="let subProject of subProjects" [value]="subProject">
                            {{subProject.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div *ngIf="subProjectFilterControl.value.id">
                    <button *appIfPermission="'WRITE'; data: subProjectFilterControl.value; type: 'SubProject'"
                            type="button"
                            mat-raised-button color="accent"
                            id="r2-cloud-sub-project-edit"
                            (click)="onEditSubProject(subProjectFilterControl.value)">Edit
                    </button>
                </div>

                <div *ngIf="adminService.subProject">
                    <div *ngIf="projectSharedInfo">
                        <h3>
                            Projeto Compartilhado
                        </h3>
                        <table>
                            <tr *ngIf="projectSharedInfo.project">
                                <td>Compartilhamento</td>
                                <td>:</td>
                                <td><b>{{projectSharedInfo.project.name}}</b></td>
                            </tr>
                            <tr *ngIf="projectSharedInfo.subProjectIds">
                                <td>
                                    Nº de projetos
                                </td>
                                <td>:</td>
                                <td><b>{{projectSharedInfo.subProjectIds.length}}</b></td>
                            </tr>
                            <tr *ngIf="projectSharedInfo.amountOfUserInSharedProject">
                                <td>Total de usuários</td>
                                <td>:</td>
                                <td><b>{{projectSharedInfo.amountOfUserInSharedProject}}</b></td>
                            </tr>
                            <tr *ngIf="projectSharedInfo.amountOfUniqueADUsersInSharedProject">
                                <td>Total de usuários únicos</td>
                                <td>:</td>
                                <td><b>{{projectSharedInfo.amountOfUniqueADUsersInSharedProject}}</b></td>
                            </tr>

                            <tr *ngIf="projectSharedInfo.amountOfUserInCurrentSubProject">
                                <td>Usuários desse projeto</td>
                                <td>:</td>
                                <td><b>{{projectSharedInfo.amountOfUserInCurrentSubProject}}</b></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>


        </mat-card-content>
    </mat-card>
</ng-container>